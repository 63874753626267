import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  styles,
  Heading,
  Padding,
  PinkDelimiter,
  SubHeading,
} from "../../common/styles"
import styled from "@emotion/styled"
import { StaticQuery, graphql, withPrefix } from "gatsby"
import _ from "lodash"
import Img from "gatsby-image"
import { media } from "../../common/mediaQueries"
import { Button, LinklessButton } from "../../components/button"

const Presse = () => {
  return (
    <Layout>
      <SEO
        title="Presse"
        keywords={[
          "Presse",
          "Patricia On Ice",
          "Historische Bilder",
          "Pressebilder",
          "Artikel",
        ]}
      />
      <Wrapper>
        <Padding value="2vw" axis="y" />
        <Heading color={styles.colors.blue.dark}>Presse</Heading>
        <Padding value="1vw" axis="y" />
        <PinkDelimiter />
        <StaticQuery
          query={graphql`
            query {
              pat2: file(relativePath: { eq: "patricia-on-ice-2.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 720, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              pat3: file(relativePath: { eq: "patricia-on-ice-3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 720, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              pat4: file(relativePath: { eq: "patricia-on-ice-4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 720, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          `}
          render={({ pat2, pat3, pat4 }) => {
            const items = [
              {
                caption: "Presseartikel",
                link: "/presse/presseartikel",
                linkText: "Weiter",
                pic: pat2,
              },
              {
                caption: "Pressebilder",
                link: "/presse/pressebilder",
                linkText: "Weiter",
                pic: pat3,
              },
              {
                caption: "Präsentation",
                link: withPrefix("/präsentation.pdf"),
                linkText: "Download",
                isExternal: true,
                pic: pat4,
              },
            ]
            return (
              <PostWrapper>
                {_.map(items, (item, idx) => {
                  return (
                    <Post key={idx}>
                      <ImageWrapper
                        children={
                          <Img
                            alt="Patricia On Ice - Foto"
                            fluid={_.get(item, "pic.childImageSharp.fluid")}
                          />
                        }
                      />
                      <TextContainer>
                        <SubHeading
                          color={styles.colors.blue.dark}
                          children={item.caption}
                        />
                        <Padding value="1vw" axis="y" />
                        <div style={{ textAlign: "center" }}>
                          {item.isExternal ? (
                            <a href={item.link} target='_blank' rel="noopener noreferrer">
                              <LinklessButton
                                content={`${item.linkText}`}
                                look="secondary"
                              />
                            </a>
                          ) : (
                            <Button
                              content={`${item.linkText}`}
                              linkTo={`${item.link}`}
                              look="secondary"
                            />
                          )}
                        </div>
                      </TextContainer>
                    </Post>
                  )
                })}
              </PostWrapper>
            )
          }}
        />
        <Padding value="10vw" axis="y" />
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`

const PostWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  align-content: stretch;
  width: 80vw;
  margin: 0 auto;
`

const Post = styled.div`
  width: calc(79.99vw / 3);
  background-color: ${styles.colors.lightGrey};
  border: 2vw solid white;
  display: flex;
  flex-direction: column;
  ${media.mobile} {
    flex: 0 0 100%;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  flex: 0 0 15vw;
  overflow: hidden;
  background-color: ${styles.colors.violet};
  ${media.mobile} {
    flex: 0 0 40vw;
  }
`
const TextContainer = styled.div`
  padding: 1.5vw;
`

export default Presse
